import Themed from "../components/Themed";
import Gear from "../components/error/Gear";
import { ButtonSimpleRounded } from "datamynt-ui-library";
import { useRouter } from "next/router";

const Custom404 = () => {
  const router = useRouter();

  return (
    <Themed>
      <div className="dark:bg-dark-base bg-light-transparent px-8 w-screen h-screen flex flex-col items-center justify-center">
        <div className="relative -left-8 sm:-left-10 top-8 sm:top-10 h-16 sm:h-24">
          <Gear color="#E5E5E5" animation="animate-spin-slow" />
        </div>
        <div className="flex items-end">
          <div className="h-20 sm:h-28">
            <Gear color="#E5E5E5" animation="animate-unspin-slow" />
          </div>
          <div className="h-28 sm:h-40">
            <Gear color="#3D8ECD" animation="animate-spin-slow" />
          </div>
        </div>
        <div className="space-y-6 flex flex-col py-8 items-center">
          <h1 className="text-primary text-center text-3xl sm:text-[2.5rem] font-bold font-Inter dark:text-white">
            Page not found
          </h1>
          <h5 className="text-base sm:text-2xl text-center text-gray-30 dark:text-white font-normal">
            This page doesn’t exist or was removed
            <br />
            We suggest you go back to the dashboard
          </h5>
          <ButtonSimpleRounded
            grow={true}
            size="md"
            onClick={() => router.push(`/dashboard`)}
            label="Back to dashboard"
          />
        </div>
      </div>
    </Themed>
  );
};

export default Custom404;
