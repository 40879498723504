import { useState, useEffect } from "react";
import { getCurrentTheme } from "../helpers/theme";

const Themed = ({ children }) => {
  const [theme, setTheme] = useState("light");

  useEffect(() => {
    setTheme(getCurrentTheme());
  }, []);

  return <div className={`${theme}`}>{children}</div>;
};

export default Themed;
